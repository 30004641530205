<template>

    <!-- :title="isEdit?'编辑会员类型':'新增会员类型'" -->

    <el-dialog :close-on-click-modal="false" title="订单详情" :visible.sync="show" width="600px" class="selfInputBox">

        <div class="infoContentBox">

            <p class="textLine">
                <span class="title">订单号</span>
                <span class="content">{{upDatavalue.order_no}}</span>
            </p>
            <p class="textLine">
                <span class="title">类型封面</span>
                <span class="content">
                    <img src="" alt="" v-bind:src="GLOBAL.BASE_URL + upDatavalue.renewlog.img" class="Image">
                </span>
            </p>
            <p class="textLine">
                <span class="title">商品名称</span>
                <span class="content">{{upDatavalue.renewlog.title}}</span>
            </p>
            <p class="textLine">
                <span class="title">销售价格(元)</span>
                <span class="content">{{upDatavalue.renewlog.money}}</span>
            </p>
            <p class="textLine">
                <span class="title">支付金额(元)</span>
                <span class="content">{{upDatavalue.pay_price}}</span>
            </p>
            <p class="textLine">
                <span class="title">支付时间</span>
                <span class="content">{{upDatavalue.pay_time}}</span>
            </p>
            <p class="textLine">
                <span class="title">续费开始时间</span>
                <span class="content">{{upDatavalue.term_start_time}}</span>
            </p>
            <p class="textLine">
                <span class="title">续费结束时间</span>
                <span class="content">{{upDatavalue.term_end_time}}</span>
            </p>
            <p class="textLine">
                <span class="title">订单状态</span>
                <span class="content">{{orderStatus(upDatavalue.status)}}</span>
            </p>
            <p class="textLine">
                <span class="title">支付状态</span>
                <span class="content">{{payStatus(upDatavalue.pay_status)}}</span>
            </p>


        </div>

    </el-dialog>
</template>

<script>
    export default {
        model: {
            prop: 'value',
            event: 'change'
        },
        props: {

        },
        data() {
            return {
                show: false,

                upDatavalue: {},
                // is_public: 1

                // // 类型：1年、2月、3日
                // timeTypt: [{
                //     id: 1,
                //     name: '年',
                // }, {
                //     id: 2,
                //     name: '月',
                // }, {
                //     id: 3,
                //     name: '日',
                // }, ],

                // isEdit: false,

                // // 回显图片的路径
                // photoList: [],
                // // 图片的路径
                // dialogImageUrl: "",

                // timeRange: '',

            }
        },

        watch: {

            show(value) {
                if (this.show) {} else {
                    // this.isEdit = false
                    // this.upDatavalue = {}
                }
            },
        },

        computed: {
            // Headers() {
            //     let token = localStorage.getItem("token");
            //     return {
            //         token
            //     }
            // }
        },

        methods: {

            payStatus(type) {
                // 支付状态 (0未支付，1已支付,2已退款,3部分退款),
                switch (type) {
                    case 0:
                        return "未支付"
                        break;
                    case 1:
                        return "已支付"
                        break;
                    case 2:
                        return "已退款"
                        break;
                    default:
                        return "部分退款"
                        break;
                }
            },

            orderStatus(type) {
                // status 0完成 1已完成

                switch (type) {
                    case 0:
                        return "完成"
                        break;
                    default:
                        return "已完成"
                        break;
                }
            },

            // open(val) {
            //     console.log("！！！！！！！！！！！！！！！！", val);
            //     // this.upDatavalue = {
            //     //     is_public: 1
            //     // }
            //     this.show = true
            // },

            async open(val) {

                this.$http.get('/api/reneworder/' + val.id).then((res) => {

                    if (res.code != 200) { //请求错误
                        this.$message.error(res.msg);
                    }
                    if (res.code == 200) {

                        console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);

                        this.show = true

                        this.upDatavalue = res.data

                        // this.dialogImageUrl = this.upDatavalue.img

                        // this.timeRange = [String(this.upDatavalue.start_time), String(this.upDatavalue
                        //     .end_time)]

                        // this.photoList = [{
                        //     url: this.GLOBAL.BASE_URL + this.dialogImageUrl
                        // }]

                    }
                }).catch((err) => {
                    console.log(err)
                });
            },


            // save() {
            //     // console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
            //     // // 回显图片的路径
            //     // photoList: [],
            //     // // 图片的路径
            //     // dialogImageUrl: "",
            //     // console.log(this.photoList);
            //     // console.log(this.dialogImageUrl);
            //     // console.log(this.timeRange);
            //     // return
            //     this.upDatavalue.img = this.dialogImageUrl
            //     this.upDatavalue.start_time = this.timeRange[0] ? this.timeRange[0] : ""
            //     this.upDatavalue.end_time = this.timeRange[1] ? this.timeRange[1] : ""
            //     // console.log(this.upDatavalue);
            //     if (this.isEdit) {
            //         // 更新
            //         this.$http.put('/api/renew/' + this.upDatavalue.id, this.upDatavalue).then((res) => {
            //             console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);
            //             if (res.code != 200) { //请求错误
            //                 this.$message.error(res.msg);
            //             }
            //             if (res.code == 200) {
            //                 this.show = false
            //                 this.$emit('refresh')
            //             }
            //         }).catch((err) => {
            //             console.log(err)
            //         });
            //     } else {
            //         // 新建
            //         this.$http.post('/api/renew', this.upDatavalue).then((res) => {
            //             console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);
            //             if (res.code == 200) {
            //                 this.show = false
            //                 this.$emit('refresh')
            //             }
            //             if (res.code != 200) { //请求错误
            //                 this.$message.error(res.msg);
            //             }
            //         }).catch((err) => {
            //             console.log(err)
            //         });
            //     }
            // },


            // handleRemove(file, fileList) {
            //     console.log(file, fileList)
            //     this.dialogImageUrl = ''
            // },
            // handleAvatarSuccess(res, file) {

            //     console.log("res, fileres, fileres, fileres, file", res, file);

            //     this.dialogImageUrl = res.data
            //     console.log(this.dialogImageUrl)
            // },
            // beforeAvatarUpload(file) {
            //     const isLt2M = file.size / 1024 / 1024 < 2

            //     if (!isLt2M) {
            //         this.$message.error('上传头像图片大小不能超过 2MB!')
            //     }
            //     return /* isJPG && */ isLt2M
            // },


        }
    }
</script>


<style lang="scss" scoped>
    // .selfInputBox {
    // }

    /deep/.el-input__suffix {
        // border: 1px red solid !important;
        // box-sizing: content-box !important;
        position: relative;
        right: 10px;
    }


    /deep/.el-input__prefix {
        // border: 1px red solid !important;
        // box-sizing: content-box !important;
        position: absolute;
        left: 20px;
    }


    .el-select.el-select--mini,
    .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
        width: 100%;
    }


    .uploadImg {
        // border: 1px red solid !important;
        box-sizing: content-box !important;
        position: relative;
        right: -20px;
        width: 148px;
        height: 148px;
        overflow: hidden;

        // line-height: 30px;
        /deep/.el-upload--picture-card {
            // border: 1px red solid !important;
            // box-sizing: content-box !important;
        }

        /deep/.el-upload__text {
            line-height: 30px;
            text-align: center;
            // border: 1px red solid !important;
            // box-sizing: content-box !important;
        }

        /deep/.el-upload-dragger {
            width: 100%;
            height: 100%;
            // width: 148px;
            // height: 148px;
            // line-height: 30px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }


    .infoContentBox {
        // border: 1px red solid !important;
        box-sizing: content-box !important;
        height: 500px;
        overflow-y: auto;

        .textLine {
            // border: 1px red solid !important;
            box-sizing: content-box !important;
            padding: 10px;

            .title {
                // border: 1px red solid !important;
                // box-sizing: content-box !important;
                display: inline-block;
                width: 100px;
            }

            .content {

                // border: 1px red solid !important;
                // box-sizing: content-box !important;
                .Image {
                    width: 60px;
                    height: 60px;
                    // border: 1px red solid !important;
                    // box-sizing: content-box !important;
                }
            }
        }

    }
</style>